import { Box, Tabs, Tab, Grid, Hidden } from "@mui/material";

/* ========= Drawers ========== */
import MessageDrawer from "src/components/Drawers/MessageDrawer";
import VitalsDrawer from "src/components/Drawers/VitalsDrawer";
import ReportDrawer from "src/components/Drawers/ReportDrawer";


/* ========== Tools ============ */
import TabPanel from "src/utils/tabPanel";

const ResponsiveTabs = ({ 
  children, 
  value, 
  onChange, 
  a11yProps
}) => {
  return (
    <>
      <Hidden mdDown>
        <Tabs
          value={value}
          onChange={onChange}
          aria-label="conference tab"
          variant="fullWidth"
          className="tab"
          classes={{
            indicator: "tab__indicator",
          }}
        >
          <Tab
            label="On Call"
            {...a11yProps(0)}
            classes={{
              root: "tab__root",
              selected: "tab__selected",
            }}
          />
          <Tab
            label="Consultation"
            {...a11yProps(1)}
            classes={{
              root: "tab__root",
              selected: "tab__selected",
            }}
          />
          <Tab
            label="Vitals Report"
            {...a11yProps(2)}
            classes={{
              root: "tab__root",
              selected: "tab__selected",
            }}
          />
          <Tab
            label="Pathlogy Reports"
            {...a11yProps(3)}
            classes={{
              root: "tab__root",
              selected: "tab__selected",
            }}
          />

          {/* Extra Border */}
          <span className="tab__extraBorder"></span>
        </Tabs>
      </Hidden>
      {children}
      <Hidden mdDown>
        {value === 1 && (
          <TabPanel value={1} index={1}>
            <Grid item md={6} lg={6}>
              <Box pt={1}>
                <MessageDrawer open={true} />
              </Box>
            </Grid>
          </TabPanel>
        )}
        {value === 2 && (
          <TabPanel value={1} index={1}>
            <Grid item md={6} lg={6}>
              <VitalsDrawer open={true} />
            </Grid>
          </TabPanel>
        )}
        {value === 3 && (
          <TabPanel value={1} index={1}>
            <Grid item md={6} lg={6}>
              <ReportDrawer open={true} />
            </Grid>
          </TabPanel>
        )}
      </Hidden>
    </>
  );
};

export default ResponsiveTabs;
