const CryptoJS = require("crypto-js");

export default function decrypter(data) {
  return JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(data,  "gkonweb", 
  {
      keySize: 128 / 8,
      iv: "gkonweb",
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })));
}