import { forwardRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Slide,
  Button
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#282c34",
    minHeight: "250px !important"
  },
  title: {
    color: "#fff",
    fontSize: 24,
    letterSpacing: "0.05em",
    userSelect: "none !important"
  },
  icon: {
    color: "#fff"
  },
  desc: {
    color: "#fff",
    fontSize: 20,
    fontWeight: 300
  },
  content: {
    display: "flex",
    // alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "column"
  },
  button: {
    color: "#fff",
    textTransform: "capitalize",
    borderRadius: "13px"
  }
}))


const AskDialog = ({open, setOpen}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false)
        }
      }}
      classes={{
        paper: classes.root
      }}
      fullWidth
      maxWidth="sm"
      TransitionComponent={Transition}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.title}>Choose One</Typography>
          <IconButton className={classes.icon} onClick={() => setOpen(false)} >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.desc}>Are you a Doctor?</Typography>
          <Button 
            className={classes.button}
            endIcon={<ArrowRightAltIcon />}
            variant="contained"
            onClick={() => {
              window.open("https://tracelyfe-doctor.netlify.app/", "_self")
            }}
          >
            Click Here
          </Button>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.desc}>Are you a Patient?</Typography>
          <Button 
            className={classes.button}
            endIcon={<ArrowRightAltIcon />}
            variant="contained"
            onClick={() => {
              window.open("https://tracelyfe-doctor.netlify.app/", "_self")
            }}
          >
            Click Here
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AskDialog;