import {
  ILocalAudioTrack,
  ILocalVideoTrack,
  IRemoteAudioTrack
} from "agora-rtc-sdk-ng";
import { useRef, useEffect } from "react";

import classes from "src/styles/player.module.scss"

export interface VideoPlayerProps {
  videoTrack: ILocalVideoTrack | undefined;
  audioTrack: ILocalAudioTrack | undefined;
}

const RemoteMediaPlayer = (props: VideoPlayerProps) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(!container.current) return;
    props.videoTrack?.play(container.current);

    // unmounting...
    return () => {
      props.videoTrack?.stop();
    }

  },[container, props.videoTrack])

  return(
    <>
      <div ref={container} className={classes.remote__player}></div>
    </>
  )
  
}

export default RemoteMediaPlayer;