import { useEffect } from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Hidden,
  Grid,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

/* =========== React Redux ============ */
import { useDispatch, useSelector } from "react-redux";
import { report } from "src/redux/actions";


/* =========== Views ============ */
import ReportBox from "./report";

/* =========== Stylesheet ============= */
import classes from "src/styles/drawer.module.scss";

function ReportDrawer({
  open, 
  setOpen,
  onHide
}){

  const dispatch = useDispatch();
  const reports = useSelector(state => state.report.reportData);
  const meetData = useSelector(state => state.meet.meetData);


  useEffect(() => {
    // let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjM4MTg1NTk0LCJqdGkiOiI3NmRjMmFlMmZhNGQ0MDFhYjYwYTA5ZGRlNDJkZGQ3MyIsInVzZXJfaWQiOjUzfQ._i3zp0fjzcFFZ4pvmpT1sZRdUH2izhc07_5cWOP9Dr8"
    // let query = "profile_id=24"
    console.log("Meet Data form report index", meetData)
    if(meetData){
      let query = `profile_id=${meetData.emp_profile_id}`;
      let token = meetData.auth;
      console.log( "All Reports", token)
      dispatch(report.fetchEmployeeReports(token, query))
    }
  },[meetData])

  // Close the drawer and show the bottom bar 

  const closeDrawer = () => {
    setOpen(false)
    onHide(false)
  }
  
  return (
    <Drawer
      // variant="persistent"
      anchor="right"
      open={open}
      className={classes.report}
      classes={{
        paper: classes.report__paper
      }}
      hideBackdrop
    >
     <Box className={classes.report__inner}>
      <Box className={classes.report__inner__header}>
        <Typography className={classes.report__inner__header__title}>Pathalogy Report</Typography>
        <Hidden mdUp>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </Hidden>
      </Box>
      <Typography className={classes.report__inner__info}>
        This report is uploaded by user.
      </Typography>

      {/*  Interal Scroll */}
      <Box pt={2} className={classes.report__inner__scroller}>
        <Grid container>
          {
            reports && reports.results.length !== 0
            ?
            reports.results.map((items) => 
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <ReportBox repo={items} />
              </Grid>
            )
            :
            <Box>
              <Typography>No report uploaded by the user yet.</Typography>
            </Box>
          }
        </Grid>
        
      </Box>

     </Box>
    </Drawer>
  )
}

export default ReportDrawer;