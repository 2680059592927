import {
  Box,
  Typography,
  IconButton
} from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { report } from "src/redux/actions";

import { toast } from "react-toastify";

import FileIcon from "src/assets/file.png";

/* ============ Stylesheet ============= */
import classes from "src/styles/report.module.scss";

export default function ReportBox({repo}) {

  const dispatch = useDispatch();
  const meetData = useSelector(state => state.meet.meetData);

  const viewReport = (item) => {
    if(item && meetData) {
      let token = meetData.auth;
      // let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjM4MTg1NTk0LCJqdGkiOiI3NmRjMmFlMmZhNGQ0MDFhYjYwYTA5ZGRlNDJkZGQ3MyIsInVzZXJfaWQiOjUzfQ._i3zp0fjzcFFZ4pvmpT1sZRdUH2izhc07_5cWOP9Dr8"
      console.log("view Report", token)
      const queryData = `profile_id=${meetData.emp_profile_id}&report_id=${item.id}`;
      dispatch(report.viewReports(token, queryData))
        .then(res => {
          let url = res.data.data;
          window.open(url, "_blank")
        })
    }
  }

  const downloadURL = (item) => {

    if(item && meetData){
      let token = meetData.auth;
      // let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjM4MTg1NTk0LCJqdGkiOiI3NmRjMmFlMmZhNGQ0MDFhYjYwYTA5ZGRlNDJkZGQ3MyIsInVzZXJfaWQiOjUzfQ._i3zp0fjzcFFZ4pvmpT1sZRdUH2izhc07_5cWOP9Dr8"
      const queryData = `profile_id=${meetData.emp_profile_id}&report_id=${item.id}`;
      dispatch(report.viewReports(token, queryData))
        .then(res => {
          let fetchUrl = res.data.data;
          const fileName = new URL(fetchUrl).pathname.split('/').pop();

          fetch(fetchUrl, { method: 'GET' })
            .then(response => response.blob())
            .then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = `${fileName}`;
              document.body.appendChild(a); 
              a.click();  
              setTimeout(
                _ => { window.URL.revokeObjectURL(url); }, 
                40000); // Revoked object URL in 40 sec
              a.remove();
            })
            .catch(err => {
              console.log(err)
              toast.error("Network Error: Failed to fetch")
            })

          // download(fetchUrl, fileName)
        })
    }
    
  }

  return(
    <Box className={classes.prt} >
      <Box className={classes.prt__inner}>
        <img src={FileIcon} width="40px" height="40px" />
        <Typography className={classes.prt__inner__title}>{repo.name}</Typography>
        <Typography className={classes.prt__inner__summary}>{repo.summary}</Typography>
        <Typography className={classes.prt__inner__date}>
          {moment(repo.updated_on).format("DD/MM/YYYY")}
        </Typography>
        <Box>
          <IconButton
            onClick={() => viewReport(repo)}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            onClick={() => downloadURL(repo)}
          >
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}