import {
  Box,
  Typography
} from "@mui/material";

import GetReadingTable from "src/utils/getReadings";
import { average } from "src/utils/helper";

/* ============ Stylesheet ============= */
import classes from "src/styles/report.module.scss";

export default function GlucoseView({tableData}) {
  const readingArray = tableData.map(item => Number(item.reading1));
  return(
    <Box className={classes.glu}>
     <Box className={classes.glu__inner}>
       {/* <Box>
        <Typography>Average Fasting</Typography>
        <Typography><b>98 mg/dL</b></Typography>
       </Box>
       <Box>
        <Typography>Before Meal</Typography>
        <Typography><b>94 mg/dL</b></Typography>
       </Box>
       <Box>
        <Typography>After Meal</Typography>
        <Typography><b>97 mg/dL</b></Typography>
       </Box> */}
       <Box>
        <Typography>Random</Typography>
        <Typography><b>{average(readingArray).toFixed(2)} mg/dL</b></Typography>
       </Box>
     </Box>
     {/* Table  */}
     <Box minHeight="150px" pr={1.3}>
      <GetReadingTable rows={tableData} showGlucoseIndicator={true} />
     </Box>
    </Box>
  )
}