import {
  Box, 
  Typography
} from "@mui/material";

/* ============ Views ============= */
import GlucoseView from "./views/glucose";
import BPView from "./views/bp";
import WeightView from "./views/weight";
import SPO2View from "./views/spo2";
import TempView from "./views/temp";
import PulseView from "./views/pulse";

/* ============ Title Icons ============= */
import GlucoseIcon from "src/assets/vitals/glucose.png";
import BPIcon from "src/assets/vitals/bp.png"
import WeightIcon from "src/assets/vitals/weight.png";
import Spo2Icon from "src/assets/vitals/spo2.png";
import TempIcon from "src/assets/vitals/temp.png"

/* ============ Stylesheet ============= */
import classes from "src/styles/report.module.scss";

const VitalsView = ({vitals}) => {
  return(
    <Box className={classes.rt}>

      {/* Blood Glucose */}
      <Box className={classes.rt__box}>
        <Box className={classes.rt__box__inner}>
          <img src={GlucoseIcon} width="40px" height="40px" />
          <Typography className={classes.rt__box__inner__title}>Blood Glucose</Typography>
        </Box>
        <Box>
          {
            vitals.glucose.length !== 0
            ?
            <GlucoseView tableData={vitals.glucose} />
            :
            <Box minHeight="150px" display="flex" alignItems="center" justifyContent="center">
              <Typography sx={{textAlign: "center", fontSize: 14}}>
                This user has not done any <br /> Blood Glucose Tests
              </Typography>
            </Box>
          }
        </Box>
      </Box>

      {/* Blood Pressure */}
      <Box className={classes.rt__box} pt={2}>
        <Box className={classes.rt__box__inner} pt={2}>
          <img src={BPIcon} width="40px" height="40px" />
          <Typography className={classes.rt__box__inner__title}>Blood Pressure</Typography>
        </Box>
        <Box>
          {
            vitals.blood_pressure.length !== 0
            ?
            <BPView tableData={vitals.blood_pressure} />
            :
            <Box minHeight="150px" display="flex" alignItems="center" justifyContent="center">
              <Typography sx={{textAlign: "center", fontSize: 14}}>
                This user has not done any <br /> Blood Pressure Tests
              </Typography>
            </Box>
          }
        </Box>
      </Box>

      {/* Body Weight */}
      <Box className={classes.rt__box} pt={2}>
        <Box className={classes.rt__box__inner} pt={2}>
          <img src={WeightIcon} width="40px" height="40px" />
          <Typography className={classes.rt__box__inner__title}>Body Weight</Typography>
        </Box>
        <Box>
          {
            vitals.weight.length !== 0
            ?
            <WeightView tableData={vitals.weight} />
            :
            <Box minHeight="150px" display="flex" alignItems="center" justifyContent="center">
              <Typography sx={{textAlign: "center", fontSize: 14}}>
                This user has not done any <br /> Weight Tests
              </Typography>
            </Box>
          }
        </Box>
      </Box>

      {/* Oxygen Saturation */}
      <Box className={classes.rt__box} pt={2}>
        <Box className={classes.rt__box__inner} pt={2}>
          <img src={Spo2Icon} width="40px" height="40px" />
          <Typography className={classes.rt__box__inner__title}>Oxygen Saturation</Typography>
        </Box>
        <Box>
          {
            vitals.oxygen_saturation.length !== 0
            ?
            <SPO2View tableData={vitals.oxygen_saturation} />
            :
            <Box minHeight="150px" display="flex" alignItems="center" justifyContent="center">
              <Typography sx={{textAlign: "center", fontSize: 14}}>
                This user has not done any <br /> Oxygen Saturation Tests
              </Typography>
            </Box>
          }
        </Box>
      </Box>

      {/* Temperature */}
      <Box className={classes.rt__box} pt={2}>
        <Box className={classes.rt__box__inner} pt={2}>
          <img src={TempIcon} width="40px" height="40px" />
          <Typography className={classes.rt__box__inner__title}>Temperature</Typography>
        </Box>
        <Box>
          {
            vitals.temperature.length !== 0
            ?
            <TempView tableData={vitals.temperature} />
            :
            <Box minHeight="150px" display="flex" alignItems="center" justifyContent="center">
              <Typography sx={{textAlign: "center", fontSize: 14}}>
                This user has not done any <br /> Temperature Tests
              </Typography>
            </Box>
          }
        </Box>
      </Box>

      {/* Pulse */}
      <Box className={classes.rt__box} pt={2}>
        <Box className={classes.rt__box__inner} pt={2}>
          <img src={GlucoseIcon} width="40px" height="40px" />
          <Typography className={classes.rt__box__inner__title}>Body Pulse</Typography>
        </Box>
        <Box>
          {
            vitals.pulse.length !== 0
            ?
            <PulseView tableData={vitals.pulse} />
            :
            <Box minHeight="150px" display="flex" alignItems="center" justifyContent="center">
              <Typography sx={{textAlign: "center", fontSize: 14}}>
                This user has not done any <br /> Pulse Tests
              </Typography>
            </Box>
          }
        </Box>
      </Box>
      
    </Box>
  )
}

export default VitalsView;