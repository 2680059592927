import {
  Box,
  Typography
} from "@mui/material";

import GetReadingTable from "src/utils/getReadings";
import { average } from "src/utils/helper";

/* ============ Stylesheet ============= */
import classes from "src/styles/report.module.scss";




export default function BPView({tableData}) {
  const systolic = tableData.map(item => Number(item.reading1));
  const diastolic = tableData.map(item => Number(item.reading2));
  return(
    <Box className={classes.bp}>
     <Box className={classes.bp__inner}>
       <Box display="flex">
        <Box>
          <Typography>Average Systolic</Typography>
          <Typography><b>{average(systolic).toFixed(2)} mmHg</b></Typography>
        </Box>
        <Box ml={3}>
          <Typography>Average Diastolic</Typography>
          <Typography><b>{average(diastolic).toFixed(2)} mmHg</b></Typography>
        </Box>
       </Box>
     </Box>
     {/* Table */}
     
     <Box minHeight="150px" pr={1.3}>
      <GetReadingTable rows={tableData} showBPIndicator={true} />
     </Box>
    </Box>
  )
}