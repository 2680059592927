import { useState } from "react";
import { Box, Typography } from "@mui/material";
import Img from "src/assets/notFound.gif";
import AskDialog from "./askDialog";

export default function NotFound() {
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src={Img} width="200px" alt="not-found" />
      <Typography
        sx={{
          fontSize: 34,
          fontWeight: 500,
        }}
      >
        Did you forgot the way?
      </Typography>
      <Typography className="anchor" onClick={() => setOpen(true)}>
        Click Here
      </Typography>
      {
        open && 
        <AskDialog 
          open={open}
          setOpen={setOpen}
        />
      }
    </Box>
  );
}
