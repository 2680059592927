import {
  ILocalAudioTrack,
  ILocalVideoTrack,
  IRemoteAudioTrack
} from "agora-rtc-sdk-ng";
import { useRef, useEffect } from "react";

import classes from "src/styles/player.module.scss"

export interface VideoPlayerProps {
  videoTrack: ILocalVideoTrack | undefined;
  audioTrack: ILocalAudioTrack | undefined;
  fullWidth: Boolean;
}

const LocalMediaPlayer = (props: VideoPlayerProps) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(!container.current) return;
    props.videoTrack?.play(container.current);

    // unmounting...
    return () => {
      props.videoTrack?.stop();
    }

  },[container, props.videoTrack])

  return(
    <>
      <div 
        ref={container} 
        className={
          props.fullWidth
          ?
          classes.remote__player
          :
          classes.local__player
        }
      ></div>
    </>
  )
  
}

export default LocalMediaPlayer;