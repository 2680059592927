import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Hidden
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import ChatCenter from "src/components/Chat";

/* =========== Stylesheet ============= */
import classes from "src/styles/drawer.module.scss";

function MessageDrawer({
  open,
  setOpen,
  onHide
}){

  const closeDrawer = () => {
    setOpen(false)
    onHide(false)
  }

  return (
    <Drawer
      // variant="persistent"
      anchor="right"
      open={open}
      className={classes.msg}
      classes={{
        paper: classes.msg__paper
      }}
      hideBackdrop
    >
     <Box className={classes.msg__inner}>
      <Box className={classes.msg__inner__header}>
        <Typography className={classes.msg__inner__header__title}>In-call messages</Typography>
        <Hidden mdUp>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </Hidden>
      </Box>
      <Typography className={classes.msg__inner__info}>
        Messages can only be seen by people in the call.
      </Typography>
      <Box>
        <ChatCenter />
      </Box>
     </Box>
    </Drawer>
  )
}

export default MessageDrawer;