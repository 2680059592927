import { ThemeProvider, StyledEngineProvider } from "@mui/material";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

/* ========== Custome Theme =========== */
import theme from "./theme";

/* =======================
  Views For Routes
 ========================= */
import Meet from "./Meet.js";
import LeaveScreen from "src/components/leave";
import NotFound from "src/components/NotFound";

/* =======================
  StyleSheet
 ========================= */
import "./App.scss";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Router>
            <Switch>
              <Route exact path="/meet/:room">
                <Meet />
              </Route>
              <Route exact path="/">
                <Redirect to="/meet" />
              </Route>
              <Route exact path="/feedback">
                <LeaveScreen />
              </Route>
              <Route exact path="*">
                <NotFound />
              </Route>
            </Switch>
          </Router>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
