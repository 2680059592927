import axios from "axios";
import {
  FETCH_VITALS,
  FETCH_REPORTS
} from "../constants/report";


const REPORT_BASE_URL = `${process.env.REACT_APP_API_URL}/doctor/api`;
let headers = {'Content-Type': 'application/json'};

export const fetchVitalReports = (authToken, queryParams) => async (dispatch, getState) => {
  try {
    if(authToken){
      headers['Authorization'] = `Bearer ${authToken}`;
      const response = await axios.get(`${REPORT_BASE_URL}/vital-graph/?${queryParams}`,
        { headers }
      );
      if(response.status === 200){
        console.log("data", response)
        dispatch({type: FETCH_VITALS, data: response.data.data})
      }
    }
  } catch (err) {
    throw err;
  }
}


export const fetchEmployeeReports = (authToken, queryParams) => async (dispatch, getState) => {
  try {
    if(authToken){
      headers['Authorization'] = `Bearer ${authToken}`;
      const response = await axios.get(`${REPORT_BASE_URL}/patient_reports/?${queryParams}`,
        { headers }
      );
      if(response.status === 200){
        console.log("data", response)
        dispatch({type: FETCH_REPORTS, data: response.data.data})
      }
    }
  } catch (err) {
    throw err;
  }
}

export const viewReports = (authToken, queryParams) => async (dispatch, getState) => {
  try {
    if(authToken){
      headers['Authorization'] = `Bearer ${authToken}`;
      const response = await axios.get(`${REPORT_BASE_URL}/get-employee-report/?${queryParams}`,
        { headers }
      );
      if(response.status === 200){
        return response;
      }
    }
  } catch (err) {
    throw err;
  }
}