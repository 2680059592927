import axios from "axios";
import {
  INITIAL_DATA,
  UPDATE_MESSAGE,
  CONFIRM_LEAVE,
  UPDATE_PAGE_NUM
} from "../constants/meet";

const MESSAGE_BASE_URL = `${process.env.REACT_APP_API_URL}/callingapp/api`;
let headers = {'Content-Type': 'application/json'};


export const initialMeet = (data) => (dispatch, getState) => {
  if(data){
    dispatch({ type: INITIAL_DATA, data: data })
  }
}

export const fetchMessage = (queryParams, auth) => async (dispatch, getState) => {
  let token = getState().meetData;
  try {
    if(auth){
      headers['Authorization'] = `Bearer ${auth}`;
      const response = await axios.get(`${MESSAGE_BASE_URL}/get-chat-messages/?${queryParams}`,
        { headers }
      );
      if(response.status === 200){
        let data = response.data.data.results;
        let prev = response.data.data.previous;
        let next = response.data.data.next;


        // Update message recursively
        data && data.map(item => dispatch(updateMessage(item, prev, next )))
      }
    }
  } catch (err) {
    throw err;
  }
}

export const updateMessage = (data, prev, next) => (dispatch, getState) => {
  if(data) {
    dispatch({ type: UPDATE_MESSAGE, data: data, prev: prev, next: next  })
  }
}


export const confirmLeave = (data) => (dispatch, getState) => {
  if(data) {
    dispatch({ type: CONFIRM_LEAVE, data: data })
  }
}


export const updatePageNum = (data) => (dispatch, getState) => {
  console.log("From Actin ", data)
  if(data){
    
    dispatch({type: UPDATE_PAGE_NUM, data: data})
  }
}