import {
  INITIAL_DATA,
  UPDATE_MESSAGE,
  CONFIRM_LEAVE,
  UPDATE_PAGE_NUM
} from "../constants/meet";

const initialState = {
  meetData: null,
  msgData: {
    prev: null,
    next: null,
    results: []
  },
  confirmLeave: null,
  pageNum: 1
}

export default function meet(state = initialState, action) {
  switch(action.type){

    case INITIAL_DATA:
      return { ...state, meetData: action.data }

    case UPDATE_MESSAGE:
      return { ...state, msgData: {
        prev: action.prev,
        next: action.next,
        results: [
          ...state.msgData.results,
          {
            id: action.data.id,
            text_message: action.data.content.content,
            name: action.data.from_user.name,
            date: action.data.created_at,
            user: action.data.from_user.id,
            type: action.data.content.type,
            document: action.data.content.document || null
          }
        ]  
      }
    }

    case CONFIRM_LEAVE:
      return { ...state, confirmLeave: action.data }

    case UPDATE_PAGE_NUM: 
      return {...state, pageNum: state.pageNum + 1}

    default:
      return state;
  }
}