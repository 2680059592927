
import { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Avatar,
  Hidden
} from "@mui/material";
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import MobileEdgeDrawer from 'src/components/Drawers/MobileDrawer';

/* ========= Drawers ========== */
import MessageDrawer from "src/components/Drawers/MessageDrawer";
import VitalsDrawer from "src/components/Drawers/VitalsDrawer";
import ReportDrawer from "src/components/Drawers/ReportDrawer";


/* ========= StyleSheet ========= */
import classes from "src/styles/navbar.module.scss";

const BottomBar = ({
  onMute,
  onUnmute,
  muteState,
  onCameraOn,
  onCameraOff,
  cameraState,
  onLeave,
  onFullScreen,
  onExitFullScreen,
  isFullScreen,
  onOpenMessageDrawer,
  onCloseMessageDrawer,
  isMessageDrawer
}) => {

  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [msgOpen, setMsgOpen ] = useState(false);
  const [vitalOpen, setVitalOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);

  const toggleMsgDrawer = () => {
    setOpen(false)
    setMsgOpen(true);
    setHide(true);
  }

  const toggleVitalDrawer = () => {
    setOpen(false)
    setVitalOpen(true);
    setHide(true);
  }
  const toggleReportDrawer = () => {
    setOpen(false)
    setReportOpen(true);
    setHide(true);
  }



  // Close the drawer if window width > 900px

  const handleResize = () => {
    if(window.innerWidth > 900){
      setMsgOpen(false)
      setVitalOpen(false)
      setReportOpen(false)
      setHide(false);
    }
  }

  useEffect(()=>{

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);

  },[])

  return(
    <>
      <AppBar className={
        !hide
        ?
        classes.bottom__root
        :
        classes.bottom__hide
      }>
        <Toolbar>
          <Box className={classes.left}>
            {/* <Typography className={classes.timer}>{'890'}</Typography> */}
          </Box>
          <Box className={classes.middle}>
            
            {/* Handling Mic */}
            {
              !muteState
              ?
              <IconButton onClick={onMute}>
                <Avatar classes={{ root: classes.ava__icon }}>
                  <MicIcon />
                </Avatar>
              </IconButton>
              :
              <IconButton onClick={onUnmute}>
                <Avatar classes={{ root: classes.ava__icon }}>
                  <MicOffIcon />
                </Avatar>
              </IconButton>
            }
            {
              !cameraState
              ?
              <IconButton onClick={onCameraOn}>
                <Avatar classes={{ root: classes.ava__icon }}>
                  <VideocamIcon />
                </Avatar>
              </IconButton>
              :
              <IconButton onClick={onCameraOff}>
                <Avatar classes={{ root: classes.ava__icon }}>
                  <VideocamOffIcon />
                </Avatar>
              </IconButton>
            }
            <IconButton onClick={onLeave}>
              <Avatar classes={{ root: classes.ava__icon }} sx={{color: "red"}}>
                <CallEndIcon />
              </Avatar>
            </IconButton>
          </Box>
          <Box className={classes.right}>

            {/* Removed because we don't need this in new layout */}
            <Hidden mdUp>
              <IconButton
                onClick={() => setOpen(true)}
              >
                <Avatar classes={{ root: classes.ava__icon }}>
                  <KeyboardArrowUpIcon />
                </Avatar>
              </IconButton>
            </Hidden>
            {/* {
              !isMessageDrawer
              ?
              <IconButton onClick={onOpenMessageDrawer}>
                <Avatar classes={{ root: classes.ava__icon }}>
                  <ModeCommentIcon/>
                </Avatar>
              </IconButton>
              :
              <IconButton onClick={onCloseMessageDrawer}>
                <Avatar classes={{ root: classes.ava__icon }} sx={{color: "#2D809F"}}>
                  <ModeCommentIcon/>
                </Avatar>
              </IconButton>
            } */}
            {/* {
              !isFullScreen
              ?
              <IconButton onClick={onFullScreen}>
                <Avatar classes={{ root: classes.ava__icon }}>
                  <OpenInFullIcon />
                </Avatar>
              </IconButton>
              :
              <IconButton onClick={onExitFullScreen}>
                <Avatar classes={{ root: classes.ava__icon }}>
                  <CloseFullscreenIcon />
                </Avatar>
              </IconButton>
            } */}
          </Box>
        </Toolbar>
        {
          open && 
          <MobileEdgeDrawer 
            open={open}
            setOpen={setOpen}
            muteState={muteState}
            onMute={onMute}
            onUnmute={onUnmute}
            cameraState={cameraState}
            onCameraOn={onCameraOn}
            onCameraOff={onCameraOff}
            onLeave={onLeave}
            onMsgOpen={toggleMsgDrawer}
            onVitalOpen={toggleVitalDrawer}
            onReportOpen={toggleReportDrawer}
          />
        }

        {
          msgOpen && 
          <MessageDrawer 
            open={msgOpen}
            setOpen={setMsgOpen}
            onHide={setHide}
          />
        }

        {
          vitalOpen &&
          <VitalsDrawer 
            open={vitalOpen}
            setOpen={setVitalOpen}
            onHide={setHide}
          />
        }

        {
          reportOpen &&
          <ReportDrawer 
            open={reportOpen}
            setOpen={setReportOpen}
            onHide={setHide}
          />
        }
      </AppBar>
      
      <Hidden mdUp>
       {
         hide && 
         <Box position="relative">
          <IconButton
            onClick={() => setOpen(true)}
            className={classes.global__drawer__mobile}
          >
            <Avatar classes={{ root: classes.ava__icon }}>
              <KeyboardArrowUpIcon />
            </Avatar>
          </IconButton>
        </Box>
       }
      </Hidden>
      
    </>
  )
}

export default BottomBar;