import {useState, useEffect, useRef} from "react";
import AgoraRTC from "agora-rtc-sdk-ng";

/* ========== Material UI ============ */
import {
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Tabs,
  Tab,
  Grid,
  Container,
  Hidden
} from "@mui/material";
import { makeStyles } from "@mui/styles";

/* ========== Navbar ============ */
import BottomBar from "src/components/Navbar/bottomBar";

/* ========== React Router ============ */
import { useParams, useHistory } from "react-router-dom";

/* ========== React Redux ============ */
import { useSelector, useDispatch } from "react-redux";
import { meet } from "src/redux/actions";

/* ========== Hooks ============ */
import useAgora from "src/hooks/useAgora";
import useFullscreenStatus from "src/hooks/useFullScreen";

/* ========== Tools ============ */
import TabPanel from "src/utils/tabPanel";
import a11yProps from "src/utils/a11yProps";

/* ========== Players ========== */
import TracelyfePlayer from "src/layouts/TracelyfePlayer";



/* ========= Importing decrypter algorithm ========== */
import decrypter from "src/utils/decrypter";

/* ========= Socket context to send data to deep nested component ========== */
import SocketContext from "src/context/socket";

import { toast } from "react-toastify";

import ResponsiveTabs from "src/layouts/ResponsiveTabs"


/* =================================
  Initializing Agora SDK as Client 
=================================== */
const client =  AgoraRTC.createClient({codec: "h264", mode: "rtc"}); // For Video Call
const shareScreenClient = AgoraRTC.createClient({codec: "h264", mode: "rtc"}); // For Screen Share
const AGORA_APP_ID = "c1e4a9d09bb740569323471067a78551";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
  },
}))

const Meet = () => {

  const classes = useStyles();
  const { room } = useParams(); // Routing Stuff
  const history = useHistory();

  // Redux Stuff
  const dispatch = useDispatch();
  const meetData = useSelector(state => state.meet.meetData);
  const confirmLeave = useSelector(state => state.meet.confirmLeave);

  // Socket Refs
  const wsMsg = useRef(null);
  const wsMeet = useRef(null);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(null);
  const [msgOpen, setMsgOpen] = useState(false);
  /**
   * Decrypting data 
   */

  useEffect(() => {
    const uri = room;
    const decodeuri = decodeURIComponent(uri)
    // console.log("decoded uri", decodeuri)
    const decryptData = decrypter(decodeuri)
    console.log(decryptData)
    dispatch(meet.initialMeet(decryptData))

  },[])

  // React Ref 
  useEffect(() => {
    if(meetData){

      // Meet base socket 

      wsMeet.current = new WebSocket(`wss://admin.tracelyfe.com/call_end/${meetData.room_id}/`)

      wsMeet.current.onopen = (e) => {
        wsMeet.current.send(
          JSON.stringify({
            room: meetData.room_id,
            doctorJoined: true,
            active: true,
            remote_mute: false
          })
        )
      }

      wsMeet.current.onerror = (e) => {
        console.log(e)
      }

      wsMeet.current.onmessage = (e) => {
        const data = JSON.parse(e.data)
        console.log("Updated", data )
        if(data.doctorJoined === false){
          console.log("leave successfully")
          history.replace({
            pathname: `/feedback`,
            search: "?leave=true"
          })

        }
      }

      // message Socket

      wsMsg.current = new WebSocket("wss://admin.tracelyfe.com/chat/");

      wsMsg.current.onopen = (e) => {
        wsMsg.current.send(
          JSON.stringify({
            token: meetData.auth,
            room_id: meetData.room_id,
            // type: "doctor"
          })
        )
      }

      wsMsg.current.onmessage = (e) => {
        const data = JSON.parse(e.data)
        dispatch(meet.updateMessage(data))
      }

    }

  },[meetData])


 


  /**
   * @desc This hook will hold all the functions and the states for user call
   * @states localAudioTrack, localVideoTrack, micState, cameraState, isHostLeft,
   * @functions leave(), join(), mute(), unMute(), cameraoff(), cameraon(), remoteUsers[],
   * @required useAgora.tsx
   */

  const {
    localAudioTrack,
    localVideoTrack,
    joinState,
    micState,
    cameraState,
    leave,
    join,
    mute,
    unMute,
    cameraOn,
    cameraOff,
    remoteUsers,
    setJoinState,
    setRemoteUsers   
  } = useAgora(client);

  /**
   * @desc initiate call with join()
   * @param string $appId, $channel, $token, $uid
   * @required $appId, $channel, $token,
   */

  useEffect(() => {
    // let token = "0062ba6ef3aed4c44438b47cf46cd2883e5IABbtPKDzVrnbbtMXkROC1Yhir3ZkBUdJxVdsmmj4xUgzgx+f9gAAAAAEABrCwS2g8ulYQEAAQACy6Vh"
    // let channel = "test"
    setLoading(true)
    setOpen(true)
    setText("Setting up your meeting room...")
    // join(AGORA_APP_ID, channel, token)
    //   .then((res) => {
    //     console.log("Working...", res)
    //     setLoading(false)
    //     setOpen(false)
    //   })
    //   .catch((err) => {
    //     console.log("error...", err.message)
    //     setLoading(false)
    //     // setOpen(false)
    //     setText(err.message)
    //     cameraOff();
    //   })
    
    if(meetData){
      join(AGORA_APP_ID, meetData.agora_channel, meetData.agora_token)
      .then((res) => {
        console.log("Working...", res)
        setLoading(false)
        setOpen(false)
      })
      .catch((err) => {
        console.log("error...", err.message)
        setLoading(false)
        // setOpen(false)
        setText(err.message)
        cameraOff();
      })
    } else {
      setLoading(false)
      setText("Network Error: Having issues in fetching room details.")
    } 
  },[meetData])


  /**
   * @desc Full Screen and Exit Screen with useFullScreenStatus()
   * @req useFullScreen.js
   */

  const maximizableElement = useRef(null);
  let isFullScreen, setIsFullScreen;
  let errorMessage;

  try {
    [isFullScreen, setIsFullScreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = "Fullscreen not supported";
    isFullScreen = false;
    setIsFullScreen = undefined;
  }

  const handleExitFullScreen = () => document.exitFullscreen();


  /**
   * Handling Tabs 
   * 
   */
  
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  }

  // Leave user and notify the backend 

  useEffect(() => {
    if(confirmLeave && confirmLeave.leave === true){
      if(wsMeet.current && meetData){
        wsMeet.current.send(
          JSON.stringify({
            room: meetData.room_id,
            doctorJoined: false,
            active: false,
            remote_mute: false
          })
        )
      }
    }
  },[confirmLeave])


  /**
   * 
   * Fetch Old Message 
   * In-case -> user refresh the page 
   * 
   */

  const pageNum = useSelector(state => state.meet.pageNum)

  useEffect(() => {
    if(meetData){
      console.log(pageNum)
      let query = `room_id=${meetData.room_id}&page=${pageNum}`
      let token = meetData.auth;
      dispatch(meet.fetchMessage(query, token))
    }
    
  },[meetData, pageNum])

  const renderConference = () => {
    return (
      <Box ref={maximizableElement}>
        {
          !open && !loading
          ?
          <Container maxWidth={false} sx={{width: "100vw"}}>
            {/* <TopBar /> */}
            <ResponsiveTabs
              value={value}
              onChange={handleTabChange}
              a11yProps={a11yProps}
            >
              <Grid container spacing={2}>
                <Grid 
                  item 
                  xs={12}
                  sm={12}
                  md={
                    value !== 0 && value <= 3 
                    ?
                    6
                    :
                    12
                  } 
                  lg={
                    value !== 0 && value <= 3 
                    ?
                    6
                    :
                    12
                  } 
                  sx={{position: "relative"}}
                >
                  <Box pt={1}>
                    <TracelyfePlayer 
                      localAudioTrack={localAudioTrack}
                      localVideoTrack={localVideoTrack}
                      remoteUsers={remoteUsers}
                    />
                    <BottomBar 
                      onMute={mute}
                      onUnmute={unMute}
                      muteState={micState}
                      onCameraOn={cameraOn}
                      onCameraOff={cameraOff}
                      cameraState={cameraState}
                      onLeave={ () => leave(meetData.room_id)}
                      isFullScreen={isFullScreen}
                      onFullScreen={setIsFullScreen}
                      onExitFullScreen={handleExitFullScreen}
                      isMessageDrawer={msgOpen}
                      onOpenMessageDrawer={() => setMsgOpen(true)}
                      onCloseMessageDrawer={() => setMsgOpen(false)}
                    />
                  </Box>
                </Grid>

                  {/**
                   * 
                   * All TabPanel and child grid rendering here 
                   * Please check ResponsiveTabs component for better understanding
                   * I have separated things because code looks very ugly :)
                   * Also we have to make mobile responsive conference
                   * 
                   * */}

              </Grid>
            </ResponsiveTabs>
           
          </Container>
          :
          <Backdrop open={open} className={classes.backdrop}>
            {
              loading
              ?
              <>
                <CircularProgress size={30} color="inherit" />
                <Typography
                  sx={{
                    fontSize: 18,
                    paddingTop: 2
                  }}
                >
                  {text}
                </Typography>
              </>
              :
              <Typography className="is__not__joined">
                {text}
                {/* We think, <br />
                <span>Meeting has not been started yet.</span> */}
              </Typography>
            }
          </Backdrop>
        }
  
      </Box>
    )
  }


  return (
    <SocketContext.Provider value={{ wsMsg, wsMeet }}>
      {renderConference()}
    </SocketContext.Provider>
  )

}

export default Meet;