
import { makeStyles } from "@mui/styles";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip
} from "@mui/material";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 650,
    // [theme.breakpoints.down('lg')]: {
    //   minWidth: "100%"
    // }
  },
  
}));


export default function GetReadingTable({
  rows, 
  showBPIndicator,
  showTempIndicator, 
  showWeightIndicator,
  showPulseIndicator,
  showGlucoseIndicator,
  showSpO2Indicator,
}) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* <TableCell>Parameter</TableCell> */}
            <TableCell align="center">Date {'&'} Time</TableCell>
            <TableCell align="center">Reading</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.vital_name}>
              {/* <TableCell component="th" scope="row" sx={{textTransform: "capitalize"}}>
                {
                  row.vital_name === "blood_pressure" 
                  ? "Blood Pressure" 
                  : row.vital_name === "oxygen_saturation"
                  ? "Oxygen Saturation"
                  : row.vital_name
                }
              </TableCell> */}
              <TableCell align="center">{moment(row.created_on).format("lll")}</TableCell>
              <TableCell align="center">
                {row.reading2 ? <>{row.reading1.toFixed(1)} / {row.reading2.toFixed(1)} </> : row.reading1.toFixed(1)} 
                { showBPIndicator ? <> mmHg</> : "" }
                { showTempIndicator ? <><sup>o</sup>C</> : ""}
                { showWeightIndicator ? <> Kg</> : "" }
                { showPulseIndicator ? <> bpm</> : ""}
                { showGlucoseIndicator ? <> mg/dL</> : "" }
                { showSpO2Indicator ? <>%</> : ""}
              </TableCell>
              <TableCell align="center">
                <Chip 
                  label={row.status}  
                  size="small"
                  sx={{
                    background: row.status === "alert" 
                      ?  "red" 
                      : row.status === "moderate"
                      ? "#F47920"
                      : "limegreen",

                    color: "#fff",
                    textTransform: "capitalize"
                  }} 
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}