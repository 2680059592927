import PropTypes from "prop-types";
import {
  Avatar as MatAvatar,
  Box
} from "@mui/material";

/* ============== StyleSheet =============== */
import classes from "src/styles/chat.module.scss";

export default function Avatar({name}) {
  return(
    <Box className={classes.avatar}>
      <Box className={classes.avatar__img}>
        <MatAvatar
          sx={{
            width: 30,
            height: 30
          }}
        >
          {name && name.charAt(0)}
        </MatAvatar>
      </Box>
    </Box>
  )
}

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
}