
import { useState } from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import {
  Box,
  Typography,
  SwipeableDrawer,
  IconButton,
} from '@mui/material';

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import MessageIcon from '@mui/icons-material/Message';

import CallEndIcon from '@mui/icons-material/CallEnd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SummarizeIcon from '@mui/icons-material/Summarize';



import classes from "src/styles/drawer.module.scss";

const drawerBleeding = 56;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#282C34",
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function MobileEdgeDrawer({
  open, 
  setOpen,
  muteState,
  onMute,
  onUnmute,
  cameraState,
  onCameraOn,
  onCameraOff,
  onLeave,
  onMsgOpen,
  onVitalOpen,
  onReportOpen
}) {


  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  return (
    <>
    <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(35% - ${drawerBleeding}px)`,
            overflow: 'visible',
            backgroundColor: "#282C34",
            paddingLeft: "16px",
            paddingRight: "16px"
          },
        }}
      />
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      className={classes.swipe}
    >
      <StyledBox
        sx={{
          position: 'absolute',
          top: -drawerBleeding,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          visibility: 'visible',
          right: 0,
          left: 0,
        }}
      >
        <Puller />
        <Typography sx={{ p: 2, color: '#fff' }}>You</Typography>
      </StyledBox>
      <StyledBox
        className={classes.swipe__inner}
      >
        <Box height="100%">
          {
            !muteState
            ?
            <IconButton onClick={onMute} className={classes.iconButton}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <MicIcon />
                <Typography >Mic On</Typography>
              </Box>
            </IconButton>
            :
            <IconButton onClick={onUnmute} className={classes.iconButton}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <MicOffIcon />
                <Typography >Mic Off</Typography>
              </Box>
            </IconButton>
          }
          {
            !cameraState
            ?
            <IconButton onClick={onCameraOn} className={classes.iconButton}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <VideocamIcon />
                <Typography >Camera On</Typography>
              </Box>
            </IconButton>
            :
            <IconButton onClick={onCameraOff} className={classes.iconButton}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <VideocamOffIcon />
                <Typography >Camera Off</Typography>
              </Box>
            </IconButton>
          }
          <IconButton onClick={onMsgOpen} className={classes.iconButton}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <MessageIcon />
              <Typography >Consultation</Typography>
            </Box>
          </IconButton>
          <IconButton onClick={onVitalOpen} className={classes.iconButton}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <AssessmentIcon />
              <Typography >Vitals</Typography>
            </Box>
          </IconButton>
          <IconButton onClick={onReportOpen} className={classes.iconButton}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <SummarizeIcon />
              <Typography >Report</Typography>
            </Box>
          </IconButton>
          <IconButton onClick={onLeave} className={classes.iconButton} sx={{color: "red !important"}}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <CallEndIcon />
              <Typography >End Call</Typography>
            </Box>
          </IconButton>
        </Box>
      </StyledBox>
    </SwipeableDrawer>
    </>
  );
}


export default MobileEdgeDrawer;
