import {
  Box,
} from "@mui/material";

import Avatar from "./Avatar";

/* ============== StyleSheet =============== */
import classes from "src/styles/chat.module.scss";

export default function ChatItem({
  user,
  type,
  metaName,
  media,
  timeAgo,
  name,
  image,
  textMessage
}){
  return(
    <div
      style={{animationDelay: '0.8s'}}
      className={`${classes.chat__item} ${user === "me" ? classes.me : classes.other}`}
    > 
      <div className={classes.chat__item__content}>
        {/* <div className={classes.chat__meta__name}>
          <span>{metaName}</span>
        </div> */}
        <div className={classes.chat__msg}>
          {
            type === "text"
            ?
            <>
              {textMessage}
            </>
            :
            type === "image"
            ?
            <>
              <img 
                src={media}
                alt={name}
                width="100%"
                style={{cursor: "pointer"}}
              />
            </>
            :
            type === "other"
            ?
            <>
              <span>render other document.</span>
            </>
            :
            ""
          }
        </div>
        <div className={classes.chat__meta}>
          <span>{timeAgo}</span>
        </div>
      </div>
      <Avatar name={name} />
    </div>
  )
}