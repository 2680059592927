/* ========= React Stuff ========== */
import {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";

import { useDispatch } from "react-redux";
import { meet } from "src/redux/actions";

/* ======== Agora Stuff ========= */
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  MicrophoneAudioTrackInitConfig,
  CameraVideoTrackInitConfig,
  IMicrophoneAudioTrack,
  ICameraVideoTrack,
  ILocalAudioTrack,
  ILocalVideoTrack
} from "agora-rtc-sdk-ng";

export default function useAgora(client: IAgoraRTCClient | undefined): {
  localAudioTrack: ILocalAudioTrack | undefined;
  localVideoTrack: ILocalVideoTrack | undefined;
  joinState: Boolean;
  micState: Boolean;
  cameraState: Boolean;
  leave: Function;
  join: Function;
  mute: Function;
  unMute: Function;
  cameraOn: Function;
  cameraOff: Function;
  remoteUsers: IAgoraRTCRemoteUser[];
  setJoinState: Dispatch<SetStateAction<boolean>>;
  setRemoteUsers: (remoteUsers: IAgoraRTCRemoteUser[] ) => void;

} {

  /* ================== Defining States ==================== */
  const [localAudioTrack, setLocalAudioTrack] = useState<ILocalAudioTrack | undefined>(undefined);
  const [localVideoTrack, setLocalVideoTrack] = useState<ILocalVideoTrack | undefined>(undefined);
  const [joinState, setJoinState] = useState(false);
  const [micState, setMicState] = useState(false);
  const [cameraState, setCameraState] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);


  const dispatch = useDispatch();

  /* ============== Create Local Tracks ================ */
  async function createLocalTracks(
    audioConfig?: MicrophoneAudioTrackInitConfig,
    videoConfig?: CameraVideoTrackInitConfig
  ): Promise<[IMicrophoneAudioTrack, ICameraVideoTrack]> {
    const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks(audioConfig, videoConfig);
    setLocalAudioTrack(microphoneTrack);
    setLocalVideoTrack(cameraTrack);
    return [microphoneTrack, cameraTrack];
  }

  /* ========================
   * Initiating Call B/W Doctor & Patient 
   * @params -> appID, channel, token, uid  @req 
   * 
   * Argument 'String' is not assignable that's why we are using 
   * argument 'string'.
   * 
  ========================== */
  async function join(
    appID: string,
    channel: string,
    token: string,
    // uid: string, // uid?: String | number | null for random user id
  ){
    
    if(!client) return; // Return If client is intialized 

    const [microphoneTrack, cameraTrack] = await createLocalTracks();
    await client.join(appID, channel, token);
    await client.publish([microphoneTrack, cameraTrack]);
    await client.enableAudioVolumeIndicator(); // To track who is speaking.

    (window as any).client = client;
    (window as any).videoTrack = cameraTrack;
    setJoinState(true)

  }

  /* ============= Leave the Call ================ */
  async function leave(){

    // Check if audio track is On 'or' Off : 
    // if On -> stop and close the track
    if(localAudioTrack){
      localAudioTrack.stop();
      localAudioTrack.close();
    }

    // Check if video track is On 'or' Off
    // if On -> stop and close the track
    if(localVideoTrack){
      localVideoTrack.stop();
      localVideoTrack.close();
    }

    setRemoteUsers([]); // set the empty array to the leave user 
    setJoinState(false); 

    dispatch(meet.confirmLeave({leave: true}))

  }

  /* ============= Mute Local User ================ */
  async function mute(){
    console.log("Mic Track", localAudioTrack)
    if(localAudioTrack){
      localAudioTrack.setEnabled(false);
      setMicState(true)
    }
  }

  /* ============= unMute Local User ================ */
  async function unMute(){
    if(localAudioTrack){
      localAudioTrack.setEnabled(true);
      setMicState(false)
    }
  }

  /* ============= Camera On -> Local User ================ */
  async function cameraOn(){
    if(localVideoTrack){
      localVideoTrack.setEnabled(false);
      setCameraState(true)
    }
  }

  /* ============= Camera Off -> Local User ================ */
  async function cameraOff(){
    if(localVideoTrack){
      localVideoTrack.setEnabled(true);
      setCameraState(false)
    }
  }

  /* ====================
   * Remote Users Handling
   * Render every time whenever new user is joined   
   ====================== */
  useEffect(() => {
    if(!client) return; // Return If client is intialized 

    setRemoteUsers(client.remoteUsers); //Set the remote users for the local users

    // Handling published users 
    const handleUserPublished = async (
      user: IAgoraRTCRemoteUser,
      mediaType: "audio" | "video"
    ) => {
      await client.subscribe(user, mediaType);

      // Toggle re-render while state of Remote Users changed.
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    }

    // Handling unPublished users 
    const handleUserUnpublished = async (
      user: IAgoraRTCRemoteUser,
    ) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    }

    // Handling joined users 
    const handleUserJoined = async (
      user: IAgoraRTCRemoteUser,
    ) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    }

    // Handling left users 
    const handleUserLeft = async (
      user: IAgoraRTCRemoteUser,
    ) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    }

    client.enableAudioVolumeIndicator(); // To track who is speaking.

    // Initalized events  
    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);

    // Unmounting events 
    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    }

  },[client])




  /* ==================
    Return values and defined States
   =================== */
   return {
     localAudioTrack,
     localVideoTrack,
     joinState,
     micState,
     cameraState,
     leave,
     join,
     mute,
     unMute,
     cameraOn,
     cameraOff,
     remoteUsers,
     setJoinState,
     setRemoteUsers
   };
}