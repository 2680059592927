import {
  FETCH_VITALS,
  FETCH_REPORTS
} from "../constants/report";


const initialState = {
  vitalsData: null,
  reportData: null
}

export default function report( state = initialState, action ){
  switch(action.type){

    case FETCH_VITALS:
      return { ...state, vitalsData: action.data }

    case FETCH_REPORTS:
      return { ...state, reportData: action.data }

    default:
      return state;
  }
}