import {
  Box,
  Typography
} from "@mui/material";

import GetReadingTable from "src/utils/getReadings";
import { average } from "src/utils/helper";

/* ============ Stylesheet ============= */
import classes from "src/styles/report.module.scss";

export default function TempView({tableData}) {
  const readingArray = tableData.map(item => Number(item.reading1));
  return(
    <Box className={classes.tp}>
     <Box className={classes.tp__inner}>
       <Box>
        <Typography>Average Temperature</Typography>
        <Typography><b>{average(readingArray).toFixed(2)} <sup>o</sup>C</b></Typography>
       </Box>
     </Box>
     {/* Table */}
     
     <Box minHeight="150px" pr={1.3}>
      <GetReadingTable rows={tableData} showPulseIndicator={true} />
     </Box>
    </Box>
  )
}