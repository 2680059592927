import {
  Container,
  Box,
  Typography,
  CircularProgress
} from "@mui/material";

/* ========== Media Players ============ */
import LocalMediaPlayer from "src/components/MediaPlayer/LocalMediaPlayer";
import RemoteMediaPlayer from "src/components/MediaPlayer/RemoteMediaPlayer";

const TracelyfePlayer = ({
  localAudioTrack,
  localVideoTrack,
  remoteUsers
}) => {
  return(
    <>
      <Box>
        <LocalMediaPlayer 
          audioTrack={localAudioTrack} 
          videoTrack={localVideoTrack}
          fullWidth={remoteUsers.length === 0 }
        />
      </Box>
      <Box>
        {
          remoteUsers.length !== 0 
          ?
          <>
            {
              remoteUsers.map((user, index) => (
                <RemoteMediaPlayer 
                  key={index} 
                  audioTrack={user.audioTrack} 
                  videoTrack={user.videoTrack} 
                />
              ))
            }
          </>
          :
          ""
          // <Box display="flex" flexDirection="column" alignItems="center">
          //   <CircularProgress color="inherit" />
          //   <Typography
          //     sx={{
          //       fontSize: 22,
          //       paddingTop: 2,
          //       fontWeight: 500
          //     }}
          //   >
          //     Waiting for Patient...
          //   </Typography> 
          // </Box>
        }
      </Box>
    </>
  )
}

export default TracelyfePlayer;