import { useState, useContext, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Button
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

import moment from "moment";

import { useSelector, useDispatch } from "react-redux";
import { meet } from "src/redux/actions";

import SocketContext from "src/context/socket";

import ChatItem from "./chatItem";

/* ============== StyleSheet =============== */
import classes from "src/styles/chat.module.scss";

const ChatCenter = () => {

  const { wsMsg } = useContext(SocketContext);
  const msgEndRef = useRef(null);
  const observer = useRef();
  const msgs = useSelector(state => state.meet.msgData);
  const meetData = useSelector(state => state.meet.meetData);

  // Init Dispatch 
  const dispatch = useDispatch();


  // States 
  const [msg, setMsg] = useState("");
  const [pageNo, setPageNo] = useState(1);

  // Observer

  const lastEl = useCallback(node => {
    if(observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && Boolean(msgs.next)){
        console.log("Hello World ", Boolean(msgs.next))
        setPageNo(pageNo => pageNo + 1)
      }
    })

    if(node) observer.current.observe(node)
  },[msgs])


  useEffect(() => {
    if(pageNo !== 1){
      console.log("page Number", pageNo)
      dispatch(meet.updatePageNum(pageNo))
    }
  },[pageNo])
 

  const onSendMessage = (e) => {
    e.preventDefault();

    wsMsg.current.send(JSON.stringify({
      text: msg,
    }))

    setMsg("");
  
  }

  useEffect(() => {

    // Set the chatbox view to the new message

    if(msgEndRef.current){
      msgEndRef.current.scrollIntoView({ behavior: "smooth" })
    }
  },[msgs])



  return (
    <Box className={classes.main__chatcontent}>
      <Box className={classes.content__body}>
        <Box className={classes.chat__items}>
          {
            msgs && msgs.results.map((item, index) => {
              if(msgs.results.length === index + 1){
                return(
                  <div ref={lastEl}>
                    <ChatItem 
                      animationDelay={index + 2}
                      key={item.id}
                      user={item.user == meetData.doc_profile_id ? "me" : "other"}
                      textMessage={item.text_message}
                      name={item.name}
                      type={item.type}
                      media={item.document}
                      // metaName={"you"} No need right now
                      timeAgo={moment(item.data).fromNow()}
                    />
                  </div>
                )
              } else {
                return(
                  <div>
                    <ChatItem 
                      animationDelay={index + 2}
                      key={item.id}
                      user={item.user == meetData.doc_profile_id ? "me" : "other"}
                      textMessage={item.text_message}
                      name={item.name}
                      type={item.type}
                      media={item.document}
                      // metaName={"you"} No need right now
                      timeAgo={moment(item.data).fromNow()}
                    />
                  </div>
                )
              }
            })
          }
          <div ref={msgEndRef} />
        </Box>
      </Box>
      <Box className={classes.content__footer}>
        <div className={classes.sendNewMessage}>
          <form onSubmit={onSendMessage}>
            <input 
              type="text"
              placeholder="Type a message here"
              onChange={(e) => setMsg(e.target.value)}
              value={msg}
            />
            <Button
              type="submit"
              className={classes.btnSendMsg}
              id="sendMsgBtn"
              variant="contained"
            >
              <SendIcon />
            </Button>
          </form>
        </div>
      </Box>
    </Box>
  )
}

export default ChatCenter;